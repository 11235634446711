import {
  Card,
  Select,
  Table,
  Input,
  Skeleton,
  Empty,
  Spin,
  Typography,
} from "antd";
import { Collapse } from "antd"; // Add this to imports at top

import { memo, useCallback, useEffect, useId, useState } from "react";
import { pageSizeOption } from "../../Context/ApplicationContext/ApplicationState";
import PcConfiguration from "../../Services/PcConfiguration";
import { ColumnsType, TableProps } from "antd/es/table";
import Tree, { DataNode } from "antd/es/tree";
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
const { Search } = Input;
const { Text } = Typography;
const THeader = memo(
  ({
    title,
    inputName,
    setShouldRefresh,
    changeListParams,
    ifSelect,
    listParams,
    setListParams,
  }: any) => {
    return (
      <>
        <div className="">
          {ifSelect ? (
            <Select
              onSelect={(value) => {
                changeListParams("type", value);
                setListParams({ ...listParams, start: 0 });
                setShouldRefresh((x: boolean) => !x);
              }}
              bordered={false}
              size="small"
              className="invSelectVendor"
              placeholder={title}
              options={[
                { value: 0, label: "In Stock" },
                { value: 1, label: "In Use" },
                { value: 2, label: "Used" },
              ]}
              allowClear
              style={{ width: "100%" }}
            />
          ) : (
            <Search
              placeholder={title}
              className="InvSearchInput"
              allowClear
              onChange={(e) => changeListParams(inputName, e.target?.value)}
              onSearch={() => {
                setListParams({ ...listParams, start: 0 });
                setShouldRefresh((x: boolean) => !x);
              }}
              size="small"
              bordered={false}
            />
          )}
        </div>
      </>
    );
  }
);
const RedIcon = () => {
  return (
    <svg width="15" height="15" xmlns="http://www.w3.org/2000/svg">
      <circle cx="7.5" cy="7.5" r="7.5" fill="#ff4d4f" />
    </svg>
  );
};

const GreenIcon = () => {
  return (
    <svg width="15" height="15" xmlns="http://www.w3.org/2000/svg">
      <circle cx="7.5" cy="7.5" r="7.5" fill="rgb(134, 208, 103)" />
    </svg>
  );
};

type DetailsType = {
  [key: string]:
    | string
    | number
    | DetailsType
    | Array<string | number | DetailsType>;
};

const CustomTable = memo(({ dataSource, columns }: any) => {
  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      //           minWidth: "550px",
      // overflow: "scroll",
      pagination={{}}
    />
  );
});

const PcConfigurationReport = () => {
  const uid = useId();
  const [data, setData] = useState<any>([]);
  const [listParams, setListParams] = useState({
    name: "",
    start: 0,
    length: 15,
  });

  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([]);
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const tableLoading = {
    spinning: loading,
    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };
  const changeListParams = useCallback((key: string, value: any) => {
    setListParams((prev) => ({
      ...prev,
      [key]: value,
    }));
  }, []);
  const generateTreeNodes = useCallback(
    (
      data: DetailsType | string | number | Array<any>,
      parentKey = ""
    ): DataNode[] => {
      if (typeof data === "string" || typeof data === "number") {
        return [
          {
            key: `${parentKey}-${crypto.randomUUID()}`,
            title: String(data),
            isLeaf: true,
          },
        ];
      }
      if (parentKey == "FirewallStates" && Array.isArray(data)) {
        return data?.map((itm: any) => {
          return {
            key: itm?._id,
            title: (
              <Text
                style={{ fontSize: "12px", fontWeight: "bolder" }}
                key={uid}
              >
                {itm?.Profile}
                <Text
                  style={{
                    color: "rgba(182, 17, 17, 1)",
                    fontSize: "12px",
                    fontWeight: "bolder",
                    marginLeft: "2px",
                    alignItems: "end",
                  }}
                >
                  {itm?.State}
                </Text>
              </Text>
            ),
            isLeaf: true,
            onClick: (e: any) => {
              e.stopPropagation();
              console.log("itm", itm, e);
            },
          };
        });
      }

      if (parentKey == "Users" && Array.isArray(data)) {
        console.log("data", data);
        return data?.map((item, index) => ({
          key: `${parentKey}-${index}-${crypto.randomUUID()}`,
          title: (
            <span
              style={{
                color:
                  index == 1 ? "rgb(134, 208, 103)" : "rgba(182, 17, 17, 1)",
                fontSize: "12px",
                fontWeight: "bolder",
              }}
            >
              {item}
            </span>
          ),
          isLeaf: true,
        }));
      }

      if (Array.isArray(data)) {
        return data.map((item, index) => ({
          key: `${parentKey}-${index}-${crypto.randomUUID()}`,
          title:
            typeof item == "number" || typeof item == "string" ? (
              <Text
                style={{
                  color: "rgba(182, 17, 17, 1)",
                  fontSize: "12px",
                  fontWeight: "bolder",
                  marginLeft: "2px",
                  alignItems: "end",
                }}
              >
                {item}
              </Text>
            ) : (
              `${parentKey} ${index + 1} `
            ),
          children:
            typeof item == "number" || typeof item == "string"
              ? []
              : generateTreeNodes(item, `${parentKey}-${index}`),
        }));
        // }
      }

      if (data && typeof data === "object") {
        return Object.entries(data).map(([key, value]) => ({
          key: `${parentKey}-${key}-${crypto.randomUUID()}`,
          title:
            typeof value === "object" ? (
              <Text key={uid}>{key}</Text>
            ) : (
              <Text
                style={{ fontSize: "12px", fontWeight: "bolder" }}
                key={uid}
              >
                {key} :
                <Text
                  style={{
                    color: "rgba(182, 17, 17, 1)",
                    fontSize: "12px",
                    fontWeight: "bolder",
                    marginLeft: "2px",
                  }}
                >
                  {value}
                </Text>
              </Text>
            ),
          children:
            typeof value === "object"
              ? generateTreeNodes(value, `${parentKey}-${key}`)
              : [],
        }));
      }

      return [];
    },
    []
  );

  const getData = useCallback(() => {
    setLoading(true);
    PcConfiguration.getPcConfiguration(
      listParams.name,
      listParams.start,
      listParams.length
    )
      .then((res: any) => {
        const result = res?.items?.map((itm: any) => ({
          key: itm?._id,
          id: itm?._id,
          name: itm?.Details.Systeminfo["Host Name"],
          details: { ...itm?.Details },
        }));
        // console.log(
        //   "result",
        //   Object.keys(result[0]?.details).map((i: string) => i.toUpperCase())
        // );
        setData(result);
        setTotalRecords(res?.total || 0);
      })
      .finally(() => setLoading(false));
  }, [listParams]);

  useEffect(() => {
    getData();
  }, [getData, shouldRefresh]);

  useEffect(() => {
    getData();
  }, [listParams, shouldRefresh]);
  const columns: ColumnsType<any> = [
    {
      title: (
        <THeader
          title={"System Name"}
          inputName={"name"}
          changeListParams={changeListParams}
          setShouldRefresh={setShouldRefresh}
          listParams={listParams}
          setListParams={setListParams}
          ifSelect={false}
        />
      ),
      dataIndex: "name",
      key: "",
      render: (name: any, record: any) => (
        <div
          style={{ cursor: "pointer" }}
          className="ps-2 font13"
          onClick={() => {
            //   setShow("Summary");
          }}
        >
          {name}
        </div>
      ),
    },
  ];
  const handleExpand = (expanded: any, record: any) => {
    setExpandedRowKeys(expanded ? [record.key] : []);
  };
  const onChangeTable: TableProps<any>["onChange"] = (
    paging,
    f,
    sorter: any
  ) => {
    // setListParams((p) => {
    //   p.sortCol = sorter?.columnKey;
    //   p.sortDir = sorter?.order;
    //   return p;
    // });

    if (paging.current !== undefined && paging.pageSize !== undefined) {
      changeListParams("start", (paging.current - 1) * paging.pageSize);
      changeListParams("length", paging.pageSize);
      setExpandedRowKeys([]);
    }
    setShouldRefresh((x) => !x);
  };
  const title = (title: string) => {
    return title.replace(/([A-Z])/g, " $1").replace(/^./, function (str) {
      return str.toUpperCase();
    });
  };
  return (
    <>
      <Card title={"Configuration Report"} bordered={false} extra={<></>}>
        <Table
          loading={tableLoading}
          columns={columns}
          dataSource={data}
          onChange={onChangeTable}
          className="InvTable1"
          scroll={{ y: `calc(100vh - 300px)` }}
          pagination={{
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            current: listParams.start / listParams.length + 1,
            pageSize: listParams.length,
            showSizeChanger: true,
            total: totalRecords,
            pageSizeOptions: pageSizeOption,
            position: ["bottomRight"],
          }}
          expandable={{
            columnWidth: 80,
            onExpand: (expanded, record) => {
              handleExpand(expanded, record);
            },
            expandedRowKeys,
            expandedRowRender: (
              record: any
              // index: number,
              // indent: number,
              // expanded: boolean
            ) => {
              return (
                <div>
                  {data?.length > 0 ? (
                    <Skeleton
                      key={"1"}
                      active={true}
                      round={true}
                      loading={false}
                    >
                      {/* <div
                        style={{
                          overflow: "scroll",
                        }}
                      > */}
                      <Collapse
                        size="small"
                        accordion
                        items={Object?.keys(record?.details).map(
                          (i: any, index) => {
                            return {
                              key: index,
                              label: (
                                <span>
                                  {i == "FirewallStates" ? (
                                    "Firewall States"
                                  ) : i == "Netstat3389" ? (
                                    <div
                                      style={{
                                        display: "flex",
                                        gap: "5px",
                                      }}
                                    >
                                      <div>RDP Status</div>
                                      <div style={{ marginTop: "3px" }}>
                                        {(record?.details[i] == null) ===
                                        record?.details[i].length > 0 ? (
                                          <RedIcon />
                                        ) : (
                                          <GreenIcon />
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    title(i)
                                  )}
                                </span>
                              ),
                              children: (
                                <div style={{ overflow: "auto" }}>
                                  {i == "InstalledSoftware" ? (
                                    <CustomTable
                                      columns={Object.keys(
                                        record?.details[i][0]
                                      )?.map((key: any) => {
                                        return {
                                          key: key,
                                          title: key,
                                          dataIndex: key,
                                          ellipsis: true,
                                          width: "150px",
                                        };
                                      })}
                                      dataSource={record?.details[i]?.map(
                                        (itm: any, index: any) => {
                                          const val = {
                                            ...itm,
                                            key: index,
                                          };
                                          return val;
                                        }
                                      )}
                                    />
                                  ) : (
                                    <Tree
                                      autoExpandParent
                                      itemScrollOffset={250}
                                      height={500}
                                      className="pcConfigTree"
                                      showLine
                                      motion={true}
                                      switcherIcon={<DownOutlined />}
                                      treeData={generateTreeNodes(
                                        record?.details[i],
                                        i
                                      )}
                                      // treeData={record?.details[i]}
                                    />
                                  )}
                                </div>
                              ),
                            };
                          }
                        )}
                      />
                      {/* </div> */}
                    </Skeleton>
                  ) : (
                    <>
                      <Empty description={<span>No Data</span>} />
                    </>
                  )}
                </div>
              );
            },
          }}
        />
      </Card>
    </>
  );
};

export default PcConfigurationReport;
