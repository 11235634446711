import { Card, Col, Modal, Row, Spin, Table } from "antd";
import { BarChart, PieChart } from "echarts/charts";
import ReactECharts from "echarts-for-react";
import {
  useDashBoardChart,
  useGetPcConfigReport,
  useInstalledApp,
} from "../../../Services/RQDashboardService";
import { memo, useCallback, useMemo, useState } from "react";
import { pageSizeOption } from "../../../Context/ApplicationContext/ApplicationState";
import { LoadingOutlined } from "@ant-design/icons";
import { color } from "echarts";

const OsChart = memo(
  ({ data, onClick }: { data: any; onClick: (e: any) => void }) => {
    const option = useMemo(
      () => ({
        color: [
          "#f64c4c",
          "#db4cf6",
          "#4cc7f6",
          "#4cf69b",
          "#b8f64c",
          "#f6b84c",
          "#594cf6",
        ],
        tooltip: { trigger: "item" },
        xAxis: {
          boundaryGap: true,
          data: data?.osInfo?.xAxis,
          axisLabel: {
            color: "#000000",
            fontSize: 10,
            interval: 0,
          },
        },
        yAxis: { type: "value" },
        emphasis: { focus: "series" },
        series: [
          {
            name: "OS Info",
            type: "bar",
            data: data?.osInfo?.series,
            color: [
              "#37A2DA",
              "#32C5E9",
              "#67E0E3",
              "#9FE6B8",
              "#FFDB5C",
              "#ff9f7f",
              "#fb7293",
              "#E062AE",
              "#E690D1",
              "#e7bcf3",
              "#9d96f5",
              "#8378EA",
              "#96BFFF",
            ],
          },
        ],
        width: "100%",
      }),
      [data]
    );

    return (
      <ReactECharts
        key="os-chart"
        option={option}
        notMerge={true}
        lazyUpdate={true}
        onEvents={{
          click: (e: any) =>
            onClick({ s: e.name, f: "Details.Systeminfo.OS Name" }),
        }}
      />
    );
  }
);

const RamChart = memo(
  ({ data, onClick }: { data: any; onClick: (e: any) => void }) => {
    const option = useMemo(
      () => ({
        color: ["#001A6E", "#074799", "#009990", "#E1FFBB"],

        xAxis: {
          boundaryGap: true,
          data: data?.ramInfo?.xAxis,
        },
        yAxis: { type: "value" },
        tooltip: { trigger: "item" },
        emphasis: { focus: "series" },
        series: [
          {
            name: "Ram Info",
            type: "bar",
            data: data?.ramInfo?.series,
          },
        ],
        width: "100%",
      }),
      [data]
    );

    return (
      <ReactECharts
        key="ram-chart"
        option={option}
        notMerge={true}
        lazyUpdate={true}
        onEvents={{
          click: (e: any) =>
            onClick({
              s: parseInt(e.name),
              f: "Details.Memorychip.CapacityGB",
            }),
        }}
        opts={{ renderer: "svg" }}
      />
    );
  }
);

const ProcessorChart = memo(
  ({ data, onClick }: { data: any; onClick: (e: any) => void }) => {
    const option = useMemo(
      () => ({
        tooltip: { trigger: "item" },
        legend: {
          orient: "vertical",
          left: "left",
        },
        color: ["#6499E9", "#9EDDFF", "#A6F6FF", "#BEFFF7"],
        series: [
          {
            name: "Processor Based",
            type: "pie",
            radius: ["40%", "70%"],
            data: data?.processorInfo,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      }),
      [data]
    );

    return (
      <ReactECharts
        key="processor-chart"
        option={option}
        notMerge={true}
        lazyUpdate={true}
        onEvents={{
          click: (e: any) => onClick({ s: e.name, f: "Details.Cpu.Name" }),
        }}
      />
    );
  }
);
const IpChart = memo(
  ({ data, onClick }: { data: any; onClick: (e: any) => void }) => {
    const option = useMemo(
      () => ({
        tooltip: { trigger: "item" },
        legend: {
          orient: "vertical",
          left: "left",
        },
        color: ["#074173", "#D70654", "#B8D576", "#FFEFC8"],
        series: [
          {
            name: "Processor Based",
            type: "pie",
            radius: ["40%", "70%"],
            data: data?.ipInfo,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      }),
      [data]
    );

    return (
      <ReactECharts
        key="processor-chart"
        option={option}
        notMerge={true}
        lazyUpdate={true}
        onEvents={
          {
            // click: (e: any) => onClick({ s: e.name, f: "Details.Cpu.Name" }),
          }
        }
      />
    );
  }
);

const Information = () => {
  const [listParams, setListParams] = useState<{
    s: string | number;
    f: string;
    start?: number;
    length?: number;
  }>();

  const [pagingItems] = useState({
    TotalProducts: { totalRecords: 0, currentPage: 1, pageSize: 15 },
  });

  const [open, setOpen] = useState(false);
  const { data: chartData, isLoading: chartLoading } = useDashBoardChart();
  const { data: installedData } = useInstalledApp();
  const { data: pcConfigData, isLoading: tableLoading } =
    useGetPcConfigReport(listParams);
  const handleClick = useCallback((params: any) => {
    setOpen(true);
    setListParams(params);
  }, []);

  const handleModalClose = useCallback(() => {
    setOpen(false);
  }, []);

  const columns = useMemo(
    () => [
      {
        title: "Software Name",
        dataIndex: "name",
        key: "category",
        width: "70%",
        render: (value: string) => (
          <div
            onClick={() =>
              handleClick({
                s: value,
                f: "Details.InstalledSoftware.DisplayName",
              })
            }
          >
            {value}
          </div>
        ),
      },
      {
        title: "Count",
        dataIndex: "count",
        key: "category1",
        width: "30%",
      },
    ],
    [handleClick]
  );

  return (
    <>
      <Row gutter={[8, 0]}>
        <Col span={16}>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Card
                title="OS Based information"
                style={{ height: "350px" }}
                loading={chartLoading}
              >
                <OsChart data={chartData} onClick={handleClick} />
              </Card>
            </Col>
            <Col span={12}>
              <Card
                title="Ram Based Information"
                style={{ height: "350px" }}
                loading={chartLoading}
              >
                <RamChart data={chartData} onClick={handleClick} />
              </Card>
            </Col>
            <Col span={12} style={{ overflow: "auto" }}>
              <Card
                title="Processor Based Distribution"
                style={{ height: "420px" }}
                loading={chartLoading}
              >
                <ProcessorChart data={chartData} onClick={handleClick} />
              </Card>
            </Col>
            <Col span={12} style={{ overflow: "auto" }}>
              <Card
                title="Ip Based Distribution"
                style={{ height: "420px" }}
                loading={chartLoading}
              >
                <IpChart data={chartData} onClick={handleClick} />
              </Card>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <Card style={{ height: "780px" }} title="Top Installed Software">
            <Table
              className="InvTable1"
              size="small"
              columns={columns}
              style={{ overflow: "hidden", boxSizing: "border-box" }}
              scroll={{ y: "559px" }}
              dataSource={installedData?.items?.map(
                (i: any, index: number) => ({
                  ...i,
                  key: index,
                })
              )}
              pagination={{
                pageSize: pagingItems.TotalProducts.pageSize,
                total: pagingItems.TotalProducts.totalRecords,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                pageSizeOptions: [],
              }}
            />
          </Card>
        </Col>
      </Row>

      <Modal
        open={open}
        zIndex={1100}
        mask
        closable={false}
        onCancel={handleModalClose}
        width={500}
        style={{ height: "405px" }}
      >
        <Card>
          <Table
            className="InvTable1"
            loading={{
              spinning: tableLoading,
              indicator: <Spin indicator={<LoadingOutlined />} size="small" />,
            }}
            size="small"
            columns={[
              {
                title: "System Name",
                dataIndex: "SystemName",
                key: "category",
                width: "70%",
              },
            ]}
            scroll={{ y: "60vh" }}
            dataSource={pcConfigData?.items?.map((i: any, index: number) => ({
              ...i,
              key: index,
            }))}
            pagination={{
              total: pcConfigData?.totalRecords,
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              pageSizeOptions: pageSizeOption,
            }}
          />
        </Card>
      </Modal>
    </>
  );
};

export default memo(Information);
