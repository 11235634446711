import { Axios } from "axios";
import { useQuery } from "@tanstack/react-query";
import { ApiUtility } from "../../Utilities/ApiUtility.axios";
const IReports_BASE_URL = `${process.env.REACT_APP_BASE_URL}/v1/api/IReports`;

export const useGetPcConfig = (payload: any) => {
  return useQuery({
    queryKey: ["useGetPcConfig", payload],
    queryFn: () => getPcConfigReport(payload),
    enabled: !!payload,
  });
};
export const getPcConfigReport = async (payload: any) =>
  await ApiUtility.getResult(`${IReports_BASE_URL}/PcConfig`, {
    ...payload,
  });
