import {
  Card,
  Select,
  Table,
  Input,
  Skeleton,
  Empty,
  Spin,
  Typography,
  Space,
  Tag,
  Flex,
  List,
} from "antd";

import { memo, useCallback, useEffect, useId, useState } from "react";
import PcConfiguration from "../../Services/PcConfiguration";
import { ColumnsType, TableProps } from "antd/es/table";
import {
  DatabaseOutlined,
  LoadingOutlined,
  MonitorOutlined,
  WifiOutlined,
} from "@ant-design/icons";
import { useGetPcConfig } from "../../Services/RQReportsService";
const { Search } = Input;

const { Text, Title } = Typography;
const THeader = memo(
  ({
    title,
    inputName,
    setShouldRefresh,
    changeListParams,
    ifSelect,
    listParams,
    setListParams,
  }: any) => {
    return (
      <>
        <div className="">
          {ifSelect ? (
            <Select
              onSelect={(value) => {
                changeListParams("type", value);
                setListParams({ ...listParams, start: 0 });
                setShouldRefresh((x: boolean) => !x);
              }}
              bordered={false}
              size="small"
              className="invSelectVendor"
              placeholder={title}
              options={[
                { value: 0, label: "In Stock" },
                { value: 1, label: "In Use" },
                { value: 2, label: "Used" },
              ]}
              allowClear
              style={{ width: "100%" }}
            />
          ) : (
            <Search
              placeholder={title}
              className="InvSearchInput"
              allowClear
              onChange={(e) => changeListParams(inputName, e.target?.value)}
              onSearch={() => {
                setListParams({ ...listParams, start: 0 });
                setShouldRefresh((x: boolean) => !x);
              }}
              size="small"
              bordered={false}
            />
          )}
        </div>
      </>
    );
  }
);

const Report2 = () => {
  const uid = useId();
  const [data, setData] = useState<any>([]);
  const [listParams, setListParams] = useState({
    name: "",
    start: 0,
    length: 15,
  });

  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [expandedRowKeys, setExpandedRowKeys] = useState<any[]>([]);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [pagingItems] = useState({
    TotalProducts: { totalRecords: 0, currentPage: 1, pageSize: 15 },
  });

  const changeListParams = useCallback((key: string, value: any) => {
    setListParams((prev) => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  const getData = useCallback(() => {
    setLoading(true);
    PcConfiguration.getPcConfiguration(
      listParams.name,
      listParams.start,
      listParams.length
    )
      .then((res: any) => {
        const result = res?.items?.map((itm: any) => ({
          key: itm?._id,
          id: itm?._id,
          name: itm?.Details.diskdrive.SystemName,
          details: { ...itm?.Details },
        }));
        // console.log(
        //   "result",
        //   Object.keys(result[0]?.details).map((i: string) => i.toUpperCase())
        // );
        setData(result);
        setTotalRecords(res?.total || 0);
      })
      .finally(() => setLoading(false));
  }, [listParams]);

  useEffect(() => {
    getData();
  }, [getData, shouldRefresh]);

  useEffect(() => {
    getData();
  }, [listParams, shouldRefresh]);
  const columns: ColumnsType<any> = [
    {
      title: "System / OS Info",
      dataIndex: "hostName",
      key: "hostName",
      render: (_: any, record: any) => (
        <Flex gap="small" align="flex-start" justify="flex-start" vertical>
          <Text strong>
            {record?.sNo ? `${record.sNo} -` : "No Serial Number"} 
            {record.os.systemInfo || "N/A"}
          </Text>

          <Tag color="blue">{record.os.osVersion || "Unknown OS"}</Tag>

          <Text>
            Installed Date:{" "}
            {record.os.installationDate
              ? new Date(record.os.installationDate).toLocaleDateString()
              : "N/A"}
          </Text>

          <Text>Manufacturer: {record.os.systemManufacturer || "N/A"}</Text>

          <Text>System Type: {record.os.systemType || "N/A"}</Text>

          <Text>Model: {record.os.systemModel || "N/A"}</Text>
        </Flex>
      ),
    },
    {
      title: <>Processor / Network</>,
      dataIndex: "config",
      key: "config",
      render: (text: any, record: any) => (
        <Flex gap="small" align="flex-start" justify="flex-start" vertical>
          <Text strong>{text || "Unknown Processor"}</Text>
          <Space direction="vertical">
            {record.networkDetails.map((network: any, index: any) => (
              <Tag color={"green"} key={index}>
                {network.description}
              </Tag>
            ))}
          </Space>
        </Flex>
      ),
    },
    {
      title: "Hard Disk / RAM",
      dataIndex: "hardDisk",
      key: "hardDisk",
      render: (text: any, record: any) => (
        <Flex gap="small" align="flex-start" justify="flex-start" vertical>
          <Text strong>{text ? `${text} GB` : "N/A"}</Text>
          <br />
          {record?.ram?.length > 0 ? (
            <Tag color="purple">
              {record.ram[0].type || "Unknown"} - {record.ram[0].speed || "N/A"}{" "}
              MHz - {record.ram[0].capacity || "N/A"} GB
            </Tag>
          ) : (
            "No RAM Info"
          )}
        </Flex>
      ),
    },
    {
      title: "Monitor / Model / SNo",
      key: "monitor",
      render: (_: any, record: any) => (
        <>
          <Space direction="vertical">
            {record.monitors.map((monitor: any, index: any) => (
              <Flex>
                <Tag color={"geekblue"} key={index}>
                  {monitor.model}
                </Tag>
                <Tag color={"geekblue"}>{monitor.manufacturer}</Tag>
                <Tag color={"geekblue"}> {monitor.serialNumber}</Tag>
              </Flex>
            ))}
          </Space>
        </>
        // <List
        //   size="small"
        //   dataSource={record.monitors}
        //   renderItem={(monitor: any) => (
        //     <List.Item>
        //       <Text strong>{monitor.model || "Unknown Model"}</Text> -{" "}
        //       {monitor.manufacturer || "Unknown Manufacturer"} -{" "}
        //       <Tag color="blue">
        //         {monitor.serialNumber || "No Serial Number"}
        //       </Tag>
        //     </List.Item>
        //   )}
        // />
      ),
    },
  ];
  const handleExpand = (expanded: any, record: any) => {
    setExpandedRowKeys(expanded ? [record.key] : []);
  };
  const onChangeTable: TableProps<any>["onChange"] = (
    paging,
    f,
    sorter: any
  ) => {
    // setListParams((p) => {
    //   p.sortCol = sorter?.columnKey;
    //   p.sortDir = sorter?.order;
    //   return p;
    // });

    if (paging.current !== undefined && paging.pageSize !== undefined) {
      changeListParams("start", (paging.current - 1) * paging.pageSize);
      changeListParams("length", paging.pageSize);
      setExpandedRowKeys([]);
    }
    setShouldRefresh((x) => !x);
  };
  const { data: pcConfigData, isLoading: loader } = useGetPcConfig(listParams);
  const handleClick = useCallback((params: any) => {
    // setOpen(true);
    setListParams(params);
  }, []);

  const tableLoading = {
    spinning: loader,
    indicator: <Spin indicator={<LoadingOutlined />} size="large" />,
  };

  return (
    <>
      <Card style={{ height: "761px" }} title=" ">
        <Table
          className="Report2"
          loading={tableLoading}
          size="small"
          columns={columns}
          style={{
            overflow: "hidden",
            boxSizing: "border-box",
            verticalAlign: "top",
          }}
          scroll={{ y: "559px" }}
          dataSource={pcConfigData?.items?.map((i: any, index: number) => ({
            ...i,
            key: index,
          }))}
          pagination={{
            pageSize: pagingItems.TotalProducts.pageSize,
            total: pagingItems.TotalProducts.totalRecords,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            pageSizeOptions: [],
          }}
          rowKey="hostName"
          // expandable={{ expandedRowRender }}
          bordered
        />
      </Card>
    </>
  );
};

export default Report2;
